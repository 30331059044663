import * as React from "react"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"

const styleTag = {
  paddingRight: 8,
  color: '#03b7f9'
}

const style = {
  marginBottom: 6
}

const Tag = ({ tags }) => {
  return (
    <div style={style}>
      {
        tags.map(tag => {
          return (
            <Link to={`/tags/${kebabCase(tag)}/`} style={{ textDecoration: 'none' }}>
              <i key={tag} style={styleTag}>#{tag}</i>
            </Link>
          )
        })
      }
    </div>
  )
}

export default Tag